import React, { useContext, useState } from 'react'

import StyledLink from '../StyledLink'

import Title from '../TitlePage'

import {
  PageContainer,
  ItemsContainer,
  Item,
  ItemImage,
  EmptyImg,
  ItemTitle,
  ItemSubTitle,
  DetailLine,
  IconContainer,
  DeleteIcon,
  LoadingItem,
} from './styles'
import Summary from './Summary'

import CartContext from '../../Providers/CartProvider'
import { formatPrice } from '../../helpers'

const CartContent = () => {
  const { checkout, removeLineItem, goToBuy } = useContext(CartContext)
  const [removingItemIds, setRemovingItemIds] = useState([])

  const products = checkout?.lineItems?.map(item => ({
    id: item.variant.id,
    lineItemId: item.id,
    imgSrc: item.variant?.image?.src || null,
    title: item.title,
    subTitle: item.variant.title === 'Default Title' ? '' : item.variant.title,
    quantity: item.quantity,
    price: item.variant.price,
    link: `/shop/${item.variant.product.handle}`,
  })) || []

  const checkoutIsAvailable = products?.length > 0
  const totalItems = products?.reduce((acc, p) => acc + p.quantity, 0)

  const handleRemoveItem = async lineItemId => {
    if (removingItemIds.includes(lineItemId)) return
    setRemovingItemIds(ids => [...ids, lineItemId])

    await removeLineItem(lineItemId)

    setRemovingItemIds(ids => ids.filter(id => id !== lineItemId))
  }

  return (
    <PageContainer>
      <Title>Votre Panier</Title>
      {checkoutIsAvailable && (
        <>
          <ItemsContainer>
            {
              products.map(p => {
                const total = formatPrice(p.price * p.quantity)
                return (
                  <Item key={p.id}>
                    <StyledLink to={p.link}>
                      {p.imgSrc ? (
                        <ItemImage src={p.imgSrc} alt={p.title} />
                      ) : (
                        <EmptyImg />
                      )}
                    </StyledLink>
                    <StyledLink to={p.link}>
                      <ItemTitle>{p.title}</ItemTitle>
                    </StyledLink>
                    <ItemSubTitle>{p.subTitle}</ItemSubTitle>
                    <DetailLine>{`Qté : ${p.quantity}`}</DetailLine>
                    <DetailLine>{`Prix à l'unité : ${p.price} €`}</DetailLine>
                    <DetailLine>{`total : ${total} €`}</DetailLine>
                    { !removingItemIds.includes(p.lineItemId) && (
                      <IconContainer>
                        <DeleteIcon
                          onClick={() => { handleRemoveItem(p.lineItemId) }}
                        />
                      </IconContainer>
                    )}
                    {
                      removingItemIds.includes(p.lineItemId) && (
                      <LoadingItem />
                      )
}
                  </Item>
                )
              })
            }
          </ItemsContainer>
          <Summary
            goToBuy={goToBuy}
            totalPrice={checkout.totalPrice}
            totalItems={totalItems}
            disabled={removingItemIds.length > 0}
          />
        </>
      )}
    </PageContainer>
  )
}

export default CartContent
