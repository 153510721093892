import styled from 'styled-components'

const TitlePage = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  margin-bottom: 15px;
  text-align: center;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.mobile}) {
    text-align: left;
    padding-left: 24px;
  }
`
export default TitlePage
