import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import Spinner from '../Spinner'

const Container = styled.div`
  width: 100vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingCart = () => {
  const { color } = useContext(ThemeContext)
  return (
    <Container>
      <Spinner size="100px" color={color.secondary.main} />
    </Container>
  )
}

export default LoadingCart
