import React from 'react'
import styled from 'styled-components'

import StyledLink from '../StyledLink'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: center;
`

export const Text = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
`

export const SecondText = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  text-align: center;
  color: ${({ theme: { color } }) => color.secondary.main};
`

const EmptyCart = () => (
  <Container>
    <Text>Votre Panier est vide</Text>
    <StyledLink to="/shop">
      <SecondText>
        Visitez notre Boutique
      </SecondText>
    </StyledLink>
  </Container>
)

export default EmptyCart
