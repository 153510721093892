import React, { useContext } from 'react'

import Layout from '../Layout'
import SEO from '../SEO'

import { CartContent, EmptyCart, LoadingCart } from '../components/Cart'

import CartContext from '../Providers/CartProvider'

const Shop = () => {
  const { checkout, fetchingCart } = useContext(CartContext)

  const checkoutIsAvailable = checkout?.lineItems?.length > 0

  if (fetchingCart) {
    return (
      <Layout>
        <SEO title="Panier" description="Votre Panier" />
        <LoadingCart />
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title="Panier" />
      {
        checkoutIsAvailable ? <CartContent /> : <EmptyCart />
      }
    </Layout>
  )
}
export default Shop
