import React, { useContext } from 'react'
import {
  string, number, bool, func,
} from 'prop-types'
import { ThemeContext } from 'styled-components'

import Button from '../Button'

import {
  SummaryContainer, TotalPrice, TotalQuantity,
} from './styles'

const Summary = ({
  totalPrice, totalItems, disabled, goToBuy,
}) => {
  const { color } = useContext(ThemeContext)

  return (
    <SummaryContainer>
      <TotalQuantity>
        {
      `${totalItems} ${totalItems === 1 ? 'élément' : 'éléments'}`
    }
      </TotalQuantity>
      <TotalPrice>
        {`${totalPrice} €`}
      </TotalPrice>
      {
      disabled ? (
        <Button
          spinnerColor={color.text_negative}
          loading={disabled}
        >
          Acheter
        </Button>
      )
        : (
          <Button onClick={goToBuy}>Acheter</Button>
        )
    }
    </SummaryContainer>
  )
}

Summary.propTypes = {
  totalPrice: string.isRequired,
  totalItems: number.isRequired,
  disabled: bool.isRequired,
  goToBuy: func.isRequired,
}

export default Summary
