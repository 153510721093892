import React from 'react'
import styled from 'styled-components'
import { DeleteIcon as Dicon } from '../Icons'
import Spinner from '../Spinner'

export const PageContainer = styled.div``

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.mobile}) {
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.laptop}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const Item = styled.div`
  padding-bottom: 10px;
  margin: 0px 0px 15px;
  border-radius: 7px;
  background-color: ${({ theme: { color } }) => color.primary.main};
  width: 300px;
  max-width: calc(100vw - 40px);
  height: 350px;
  position: relative;
  justify-self: center;
  align-self: center;
`

export const ItemImage = styled.img`
  width: 300px;
  max-width: calc(100vw - 40px);
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`

export const EmptyImg = styled.div`
  width: 300px;
  height: 180px;
`

export const ItemTitle = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.l};
  padding-top: 5px;
  padding-left: 10px;
`

export const ItemSubTitle = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  padding-left: 10px;
  margin-bottom: 15px;
`

export const DetailLine = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  padding-left: 10px;
  margin-top: 5px;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

export const DeleteIcon = styled(Dicon)`
  font-size: 22px !important;
  cursor: pointer;

  &:hover {
    color: ${({ theme: { color } }) => color.red} !important;
  }
`

export const SummaryContainer = styled.div`
  width: 300px;
  margin: 20px auto 0;
  padding: 10px;
`

export const TotalPrice = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  margin-bottom: 10px;
`

export const TotalQuantity = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  display: flex;
  align-items: flex-end;
`

const LoadingItemContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: ${({ theme: { color } }) => color.secondary.main};
  opacity: 0.6;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoadingItem = () => (
  <LoadingItemContainer><Spinner size="60px" /></LoadingItemContainer>
)
